import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

import bgImage from "assets/images/logos/mm-cryogases-logo.png";

function Counters() {
  return (
    <>
      <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
        <Grid item xs={12} md={4}>
          <DefaultCounterCard
            count={70}
            suffix="+"
            title="Total Sell"
            //             description="What is lorem ipsum used for?
            // The lorem ipsum is a placeholder text used in publishing and graphic design. This filler text is a short paragraph that contains all the letters of the alphabet and it is used to fill empty spaces in a document so the reader can focus on the visual elements."
          />{" "}
          {/* <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} /> */}
        </Grid>
        <Grid item xs={12} md={4}>
          <DefaultCounterCard
            count={15}
            suffix="+"
            title="Happy Clients"
            //             description="What is lorem ipsum used for?
            // The lorem ipsum is a placeholder text used in publishing and graphic design. This filler text is a short paragraph that contains all the letters of the alphabet"
          />
        </Grid>
        {/* <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} /> */}
        <Grid item xs={12} md={4}>
          <DefaultCounterCard
            count={4}
            title="Product Available"
            //             description="What is lorem ipsum used for?
            // The lorem ipsum is a placeholder text used in publishing and graphic design. This filler text is a short paragraph that contains all the letters of the alphabet"
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} lg={6} style={{ display: "flex", justifyContent: "center" }}>
          <MKBox
            display={{ xs: "none", md: "none", lg: "flex" }}
            width="40rem"
            height="34rem"
            borderRadius="lg"
            ml={-10}
            mt={5}
            mb={5}
            position="relative"
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid item xs={12} lg={6} pl={3} pr={3}>
          <MKTypography
            variant="h2"
            color="yellow"
            textAlign="center"
            sx={{
              mt: 2,
            }}
          >
            Our Role in Covid 19
          </MKTypography>
          <MKTypography
            variant="body1"
            color="success"
            textAlign="justify"
            sx={{
              mt: 2,
            }}
          >
            M M Cryo Gases during the COVID-19 pandemic, the company played a critical role in the
            fight against the virus by providing oxygen to hospitals in the Palghar and Mumbai
            districts. We were designated as essential workers during the pandemic, and the company
            remained open and continued to supply oxygen to hospitals, even when many other
            businesses were closed. The company's tankers transported oxygen to hospitals around the
            clock, regardless of the lockdown situation. Our efforts were greatly appreciated by
            medical professionals and government alike. The company's commitment to providing oxygen
            to hospitals made a real difference in the lives of many people during the COVID-19
            pandemic
          </MKTypography>
        </Grid>
      </Grid>
    </>
  );
}

export default Counters;
