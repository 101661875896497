// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Presentation from "layouts/pages/presentation";
import Services from "layouts/pages/landing-pages/services";
const routes = [
  {
    name: "Home",
    route: "/home",
    component: <Presentation />,
  },
  {
    name: "About Us",
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "Serivces",
    columns: 1,
    rowsPerColumn: 3,
    collapse: [
      {
        collapse: [
          {
            name: "Liquid N2",
            route: "/services/liquid_N2",
            component: <Services serviceName="liquid_N2" />,
          },
          {
            name: "Liquid O2",
            route: "/services/liquid_O2",
            component: <Services serviceName="liquid_O2" />,
          },
          {
            name: "Dry Ice",
            route: "/services/dry_ice",
            component: <Services serviceName="dry_ice" />,
          },
        ],
      },
    ],
  },
  {
    name: "Contact Us",
    route: "/contact-us",
    component: <ContactUs />,
  },
];

export default routes;
