import borders from "assets/theme/base/borders";

// Material Kit 2 React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

export default {
  styleOverrides: {
    root: {
      // borderRadius: borderRadius.xl,
      margin: `${pxToRem(0)} ${pxToRem(0)} 0`,
    },

    media: {
      width: "auto",
    },
  },
};
