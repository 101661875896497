import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/General/IMG_8781.JPG";
// import { url } from "inspector";
import manager from "assets/images/logos/mm-cryogases-logo.png";
import tanker1 from "assets/images/Our Fleet/tanker1.jpg";
import tanker2 from "assets/images/Our Fleet/tanker2.jpg";
import tanker3 from "assets/images/Our Fleet/tanker3.jpg";
import tanker5 from "assets/images/Our Fleet/tanker51.jpg";
import tanker18 from "assets/images/Our Fleet/tanker18.jpg";
const tankerData = [
  { size: "1kL", image: tanker1 },
  { size: "2.5kl", image: tanker2 },
  { size: "3kl", image: tanker3 },
  { size: "5kl", image: tanker5 },
  { size: "18kl", image: tanker18 },
];
function AboutUs() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      ></MKBox>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="40rem"
            height="35rem"
            borderRadius="lg"
            ml={5}
            mt={10}
            mb={5}
            position="relative"
            sx={{
              backgroundImage: `url(${manager})`,
              backgroundRepeat: "no-repeat",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6} mt={5}>
          <MKTypography
            variant="h1"
            color="success"
            textAlign="center"
            ml={-5}
            mb={2}
            sx={{
              "@media (min-width: 250px) and (max-width: 950px)": {
                ml: 1,
                mt: 3,
                p: 2,
                textAlign: "center",
              },
            }}
          >
            From the management’s desk
          </MKTypography>
          <MKTypography
            variant="text"
            color="success"
            mt={1}
            mb={3}
            ml={-5}
            mr={3}
            sx={{
              textAlign: "justify",
              // "@media (min-width: 250px) and (max-width: 1100px)": {
              // },
            }}
          >
            <ul style={{ paddingLeft: "24px", paddingRight: "24px" }}>
              <li>
                M M Cryogases is a 22-year-old proprietorship firm that is headed by Milind
                Chandrakant Patil. We are a leading supplier of cryogenic gases and services in
                India. We offer a wide range of cryogenic gases, including liquid nitrogen, liquid
                oxygen, and argon. We also offer a variety of cryogenic services, such as purging
                and degassing, refrigeration in pharma bulk drug manufacturing, and high-pressure
                pneumatic testing.
              </li>
              <br />
              <li>
                We are committed to providing our customers with the highest quality products and
                services. We have a team of experienced and trained professionals who are dedicated
                to ensuring that our customers are satisfied. We also have a fleet of 10 cryogenic
                tankers that can deliver our products to customers throughout India.
              </li>
              <br />
              <li>
                We are proud to be the most preferred vendor of some of the leading companies in
                India, such as L&T, ONGC, Aarti Industries, BARC, NPCIL, GAIL, MLDB, laser machine
                users, ACTREC, and RIL. We are committed to continuing to provide our customers with
                the best possible products and services.
              </li>
            </ul>
          </MKTypography>
        </Grid>
        <Grid item xs={12}>
          <MKTypography variant="h1" color="success" textAlign="center" mt={4}>
            Our Fleet
          </MKTypography>
        </Grid>
        {tankerData.map((tanker, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={4}
            key={index}
            sx={{
              "@media (min-width: 350px) and (max-width: 600px)": {
                margin: "10px",
              },
            }}
          >
            <div
              style={{
                width: "100%",
                paddingTop: "56.25%",
                position: "relative",
              }}
            >
              <img
                src={tanker.image}
                alt={`Tanker ${index + 1}`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  borderRadius: "20px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
              />
            </div>
            <MKTypography
              variant="body1"
              sx={{ fontWeight: "bold" }}
              color="success"
              textAlign="center"
            >
              Size: {tanker.size}
            </MKTypography>
          </Grid>
        ))}
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
