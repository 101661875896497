import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image

function ContactUs() {
  return (
    <>
      <MKTypography mt={3}></MKTypography>
      <DefaultNavbar routes={routes} sticky />
      <Grid container spacing={3} alignItems="center">
        <Grid
          item
          xs={12}
          sm={10}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: "auto" }}
          mr={{ xs: "auto", lg: "auto" }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="success"
              coloredShadow="yellow.main"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="success" mb={3}>
                If you are interested in our services or have any questions, please contact us at:
              </MKTypography>
              <Grid spacing={8} xs={12} md={9} lg={12} mb={3}>
                <MKTypography variant="h6" fontWeight="light" color="success">
                  Phone: +91-9823238904 <br></br>Email: mmcryo@rediffmail.com <br></br>Location:
                  Palghar, Maharashtra,India
                </MKTypography>
              </Grid>
              <MKBox width="100%" component="form" method="post" autocomplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput variant="standard" label="Full Name" fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      // color="success"
                      type="email"
                      variant="standard"
                      label="Email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="What can we help you? "
                      placeholder="Describe your problem in at least 250 characters"
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="success">
                    Send Message
                  </MKButton>
                  <MKBox mt={5}>
                    <MKTypography variant="h6" fontWeight="regular" color="success">
                      We look forward to hearing from you and serving your cryogenic needs and
                      equipment. Thank you for choosing MM Cryo Gases!
                    </MKTypography>
                  </MKBox>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
