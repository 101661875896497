import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MKBox from "components/MKBox";
import MKTypography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";

import routes from "routes";
import footerRoutes from "footer.routes";
import bgvideo from "assets/Videos/bgvideo.mp4";

const VideoCard = () => {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <div style={{ position: "relative" }}>
        <Card style={{ width: "100%", height: "75vh", position: "relative" }}>
          <CardMedia
            component="video"
            autoPlay
            loop
            muted
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            src={bgvideo}
          />
          <CardContent
            style={{
              position: "absolute",
              color: "white",
              textAlign: "center",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <MKTypography
              color="#087f23"
              fontSize="4rem"
              fontFamily={"Roboto"}
              fontWeight={700}
              mb={1}
              sx={{
                "@media (min-width: 350px) and (max-width: 1000px)": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  mt: 17,
                  fontSize: "3rem",
                  textAlign: "center",
                },
                "@media (min-width: 200px) and (max-width: 349px)": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  mt: 20,
                  fontSize: "1rem",
                  textAlign: "justify",
                },
              }}
            >
              MM CRYOGASES
            </MKTypography>
            <MKTypography
              color="#087f23"
              fontSize="2rem"
              fontFamily={"Roboto"}
              fontWeight={300}
              mb={1}
              sx={{
                "@media (min-width: 200px) and (max-width: 1000px)": {
                  textAlign: "justify",
                  fontSize: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                },
              }}
            >
              We are a leading manufacturer of industrial gases and gas mixtures in India. We are
              committed to providing our customers with the highest quality products and services.
            </MKTypography>
          </CardContent>
        </Card>
      </div>
      <Counters />
      <Information />
      <MKBox py={15} pb={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default VideoCard;
