import React from "react";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import MKBox from "components/MKBox";
import ServicesData from "Data/Service";

function ServicesPage({ serviceName }) {
  const styles = {
    listItem: {
      borderBottom: "1px solid #ccc",
      padding: "20px",
      marginBottom: "20px",
    },
    title: {
      fontSize: "3xl",
      fontWeight: "exbold",
      color: "#E4E40A",
    },
    applications: {
      // display: "block",
      fontFamily: "Poppins, sans-serif",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
      backgroundColor: "#f5f5f5",
      color: "#087f23",
      fontSize: "1rem",
      fontWeight: 500,
    },
    animatedtext: {
      fontFamily: "Poppins, sans-serif",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    animatedTextSpan: {
      textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
      color: "#087f23",
      fontSize: "1.5rem",
      fontWeight: 500,
      textAlign: "justify",
    },
  };

  const service = ServicesData.services[serviceName];

  return (
    <div>
      <DefaultNavbar routes={routes} />
      <MKBox
        height="70vh"
        width="100%"
        sx={{
          backgroundImage: `url(${service?.icon})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      ></MKBox>
      <ul style={{ listStyleType: "none", padding: 10, margin: 20 }}>
        <li key={service?.ID} style={styles.listItem}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 style={{ ...styles.title, color: "#0C7305" }}>{service?.Title}</h1>
          </div>
          <div style={{ flex: "2" }}>
            <div style={{ ...styles.animatedtext }}>
              <ul>
                {service?.description.map((item, index) => (
                  <li style={{ ...styles.animatedTextSpan }} key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <br></br>
          <hr></hr>
          <br></br>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {/* Fix Me: Add media query to set margin-right to 0 */}
            <div style={{ flex: "1", marginRight: "40px", ...styles.applicationsSection }}>
              <h1 style={{ ...styles.title }}>Applications:</h1>
              <div style={{ ...styles.applications }}>
                <ul>
                  {service?.ApplicationsByUs.map((item, index) => (
                    <li style={{ ...styles.animatedTextSpan }} key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div style={{ flex: "1", ...styles.applicationsSection }}>
              <h1 style={{ ...styles.title }}>Safety By US:</h1>
              <div style={{ ...styles.applications }}>
                <h2 style={{ ...styles.animatedtext }}>
                  We follow strict safety measures when handling {service?.Title}, such as:
                </h2>
                <ul>
                  {service?.Safety.map((item, index) => (
                    <li style={{ ...styles.animatedTextSpan }} key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </div>
  );
}

export default ServicesPage;
