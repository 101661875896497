import LiquidN2 from "assets/images/products/LiquidN2.png";
import LiquidO2 from "assets/images/products/LiquidO2.png";
import DryIce from "assets/images/products/DryIce.png";

const ServiceData = {
  services: {
    liquid_N2: {
      ID: "liquid_N2",
      Title: "Liquid Nitrogen",
      icon: LiquidN2,
      description: [
        "Liquid nitrogen is a colourless, low-viscosity liquid that is widely used as a coolant. It is the liquefied form of the element nitrogen that consists of two nitrogen atoms sharing covalent bonds (N2). It has a boiling point of about −195.8 °C (−320 °F/ 77 K) and is produced industrially by fractional distillation of liquid air. Liquid nitrogen has many uses, mainly based on its cold temperature and low reactivity. Some of the common applications include:",
        "The freezing and transporting of food products",
        "The cryopreservation of biological samples such as sperm, eggs, and animal genetic samples",
        "The cooling of superconductors, vacuum pumps, and other equipment",
      ],
      ApplicationsByUs: [
        "Food processing: We provide liquid nitrogen for freezing, chilling, and packaging food products, such as meat, seafood, dairy, fruits, vegetables, and ready meals. Liquid nitrogen helps preserve the quality, freshness, and flavour of food products by preventing oxidation, dehydration, and bacterial growth.",
        "Medical and pharmaceutical: We provide liquid nitrogen for cryopreservation, cryosurgery, and cryotherapy. Liquid nitrogen helps preserve the viability of biological samples, such as blood, tissue, organs, stem cells, and vaccines. Liquid nitrogen also helps remove unwanted skin lesions, such as warts, moles, and skin tags.",
        "Industrial and scientific: We provide liquid nitrogen for cooling, testing, and research purposes. Liquid nitrogen helps achieve low temperatures for superconducting magnets, vacuum pumps, infrared detectors, and other equipment. Liquid nitrogen also helps conduct experiments and simulations in various fields of science and engineering.",
      ],
      Safety: [
        "Wearing appropriate safety gear, such as gloves, goggles, aprons, and masks.",
        "Using proper containers and equipment that are designed for liquid Nitrogen storage and transportation.",
        "Avoiding direct contact with liquid Nitrogen or its vapour.",
        "Avoiding inhalation or ingestion of liquid Nitrogen or its vapour.",
        "Avoiding spillage or leakage of liquid Nitrogen.",
        "Keeping liquid Nitrogen away from heat sources or flammable materials.",
        "Ventilating the area where liquid Nitrogen is used or stored.",
      ],
    },
    liquid_O2: {
      ID: "liquid_O2",
      Title: "Liquid Oxygen",
      icon: LiquidO2,
      description: [
        "Liquid oxygen is a cryogenic liquid with a boiling point of 297°F (-183°C), used in various cooling and cryogenic applications.",
        "It is obtained through fractional distillation from natural air and is commonly used as a cryogenic liquid oxidizer propellant for spacecraft rockets, often in combination with other fuels.",
        "Liquid oxygen can cause severe burns due to its extremely low temperatures, making items brittle and unstable. It is also highly flammable",
        "In addition to its industrial applications, liquid oxygen is used for efficient oxygen supply, especially in home healthcare, and for therapeutic purposes in treating conditions related to oxygen deficiency, such as chronic pain and fatigue",
      ],
      ApplicationsByUs: [
        "Medical: We provide liquid oxygen for oxygen therapy. Liquid oxygen helps deliver pure oxygen to patients who have low blood oxygen levels due to respiratory diseases or disorders.",
        "Industrial: We provide liquid oxygen for combustion processes. Liquid oxygen helps enhance the performance and reduce the emissions of industrial furnaces, boilers, kilns, and generators.",
      ],
      Safety: [
        "Wearing appropriate safety gear, such as gloves, goggles, aprons, and masks.",
        "Using proper containers and equipment that are designed for liquid oxygen storage and transportation.",
        "Avoiding direct contact with liquid oxygen or its vapour.",
        "Avoiding inhalation or ingestion of liquid oxygen or its vapour.",
        "Avoiding spillage or leakage of liquid oxygen.",
        "Keeping liquid oxygen away from heat sources or flammable materials.",
        "Ventilating the area where liquid oxygen is used or stored.",
      ],
    },
    dry_ice: {
      ID: "dry_ice",
      Title: "Dry Ice",
      icon: DryIce,
      description: [
        "Dry ice is the solid form of carbon dioxide (CO2), a molecule consisting of a single carbon atom bonded to two oxygen atoms.",
        "Dry ice is commonly used for temporary refrigeration as CO2 does not have a liquid state at normal atmospheric pressure and sublimates directly from the solid state to the gas state.",
        "It is used primarily as a cooling agent but is also used in fog machines at theatres for dramatic effects.",
      ],
      ApplicationsByUs: [
        "Food processing: We provide dry ice for freezing food products during transportation.",
        "Medical: We provide dry ice for transporting medical samples that require refrigeration.",
        "Medical: We provide dry ice for transporting medical samples that require refrigeration.",
      ],
      Safety: [
        "Wearing appropriate safety gear, such as gloves, goggles, aprons, and masks.",
        "Using proper containers and equipment that are designed for Dry Ice storage and transportation.",
        "Avoiding direct contact with dry ice or its vapour.",
        "Avoiding inhalation or ingestion of dry ice or its vapour.",
        "Avoiding spillage or leakage of dry ice.",
        "Keeping dry ice away from heat sources or flammable materials.",
      ],
    },
  },
};
export default ServiceData;
