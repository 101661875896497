import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
// Images
import bgFrontN2 from "assets/images/products/LiquidN2.png";
import bgBackN2 from "assets/images/products/LiquidN2.png";
import bgFronto2 from "assets/images/products/LiquidO2.png";
import bgBacko2 from "assets/images/products/LiquidO2.png";
import bgFrontDryIce from "assets/images/products/DryIce.png";
import bgBackDryIce from "assets/images/products/DryIce.png";

function text(parms) {
  return parms == 1 ? (
    <mktypography>
      <h4>
        Liquid Nitrogen LN<sub>2</sub>
      </h4>
    </mktypography>
  ) : (
    <mktypography>
      <h4>
        Liquid Oxygen LO<sub>2</sub>
      </h4>
    </mktypography>
  );
}
function Information() {
  return (
    <MKBox component="section">
      <Container mb={10}>
        <MKBox display="flex" justifyContent="center" alignItems="center" mt={3} mb={4}>
          <MKTypography variant="h1" color="yellow">
            Our Products{" "}
          </MKTypography>
        </MKBox>
        <Grid
          container
          item
          xs={11}
          spacing={3}
          alignItems="center"
          ml={3}
          sx={{
            "@media (min-width: 250px) and (max-width: 1200px)": {
              ml: -1,
              mt: 2,
            },
          }}
        >
          <Grid item xs={12} lg={4}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFrontN2}
                icon="touch_app"
                title={text(1)}
                description="Liquid Nitrogen is a colorless, odorless cryogenic liquid with an extremely low boiling pointIt is widely used in various industries for its unique properties and applications It has a boiling point of −195.8 °C (−320 °F; 77 K)."
              />
              <RotatingCardBack
                image={bgBackN2}
                title="Properties and Application of Liquid Nitrogen"
                description="Common applications are in freezing and transporting of food products,cryopreservation of biological samples and cooling of superconductors, vacuum pumps, and other equipment."
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={4}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFronto2}
                icon="touch_app"
                title={text(2)}
                description="Liquid Oxygen is a cryogenic liquid with a boiling point of 297°F (-183°C), used in various cooling and cryogenic applications,It is obtained through fractional distillation from natural air."
              />
              <RotatingCardBack
                image={bgBacko2}
                title="Properties and Application of Liquid Oxygen"
                description="Liuid Oxygen is commonly used as a cryogenic liquid oxidizer propellant for spacecraft rockets, often in combination with other fuels and in various cooling and cryogenic applications."
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={4}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFrontDryIce}
                icon="touch_app"
                title="Dry Ice (DI)"
                description="Dry ice is the solid form of carbon dioxide (CO2), a molecule consisting of a single carbon atom bonded to two oxygen atoms.
                It is used primarily as a cooling agent and in theatres for dramatic effects."
              />
              <RotatingCardBack
                image={bgBackDryIce}
                title="Properties and Application of Dry Ice"
                description="Dry ice is commonly used for temporary refrigeration as CO2 does not have a liquid state at normal atmospheric pressure and sublimates directly from the solid state to the gas state."
              />
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
